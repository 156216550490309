/* eslint-disable arrow-body-style */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CTABanner from 'components/Common/CTABanner'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import HuisOpgeven from 'components/Diensten/HuisOpgeven'
import OtherArticles from 'components/Nieuws/OtherArticles'
import ImageSlider from 'components/Common/ImageSlider'
import Content from 'components/Content'

// Third Party
import styled from 'styled-components'

const Dienst = styled.div``

const DefaultNieuwsTemplate = ({ data: { page, otherPages }, pageContext }) => {
  return (
    <Layout>
      {/* {() => ( */}
        <>
          <SEO seo={page.seo} socialImage={page.recapPost.banner.image} />
          <Dienst>
            <CustomBreadCrumb
              data={page}
              className="py-2"
              pageContext={pageContext}
            />
            <div className="container">
              <div className="row py-5">
                <div className="col-lg-7 pl-lg-3">
                  <Content content={page.recapPost.content} />
                </div>
                <div className="col-lg-5 pt-5 pt-lg-0 pr-lg-3">
                  <HuisOpgeven className="pb-5 pt-4 pt-lg-0 justify-content-center justify-content-lg-end" />
                  <OtherArticles
                    data={otherPages}
                    className="d-flex flex-wrap justify-content-center justify-content-lg-end"
                  />
                </div>
              </div>
              <ImageSlider data={page} className="py-5" />
            </div>
            <CTABanner data={page} />
          </Dienst>
        </>
      {/* )} */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPost(databaseId: { eq: $databaseId }) {
      title
      recapPost {
        content
        images {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 900)
              }
              publicURL
            }
          }
        }
        banner {
          image {
            localFile {
              publicURL
            }
          }
          content
          link {
            target
            title
            url
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
    otherPages: allWpPost(
      limit: 3
      filter: { databaseId: { ne: $databaseId } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          categories {
            nodes {
              name
              slug
            }
          }
          date(formatString: "DD MMMM YYYY", locale: "nl-NL")
          recapPost {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 900)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default DefaultNieuwsTemplate
