/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import Img from 'gatsby-image'

// Styles
import 'lightbox-react/style.css'

// Components
import { PrevArrow, NextArrow } from 'components/Common/Arrows'
import Lightbox from 'lightbox-react'

// Third Party
import styled from 'styled-components'
import Slider from 'react-slick'
import { size } from 'lodash'

const StyledImg = styled(Img)`
  height: auto;
  max-width: 650px;
  cursor: pointer;
  transition: transform .5s ease;

  :hover {
    transform: scale(1.2);
  }
`

const ImageSliderWrapper = styled.div``

const ImgContainer = styled.div`
  overflow: hidden;
`

const StyledSlider = styled(Slider)`
  .slick-list {
    overflow: hidden;
    margin: 0 -15px;
    width: 100%;
  }

  .slick-slide {
    margin: 0 15px;
  }

  .slick-track {
    display: flex;
  }
`

const ImageSlider = ({ data, className }) => {
  const [mediaData, setMediaData] = useState({
    photoIndex: 0,
    isVisible: false
  })

  return (
    <ImageSliderWrapper className={`${className ? `${className}` : ``}`}>
      <StyledSlider
        className="d-flex position-relative"
        infinite
        speed={500}
        slidesToShow={data.recapPost.images.length <= 2 ? data.recapPost.images.length : 3}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={4000}
        prevArrow={<PrevArrow middle />}
        nextArrow={<NextArrow middle />}
        responsive={[
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: data.recapPost.images.length <= 1 ? data.recapPost.images.length : 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1
            }
          }
        ]}
      >
        {data.recapPost.images.map((image, index) => (
          <ImgContainer role="button" tabIndex="0" onKeyPress={() => { }} onClick={() => setMediaData({ photoIndex: index, isVisible: true })}>
            <StyledImg key={index} loading="lazy" fluid={image.image.localFile.childImageSharp.fluid} alt="" />
          </ImgContainer>
        ))}
      </StyledSlider>

      {mediaData.isVisible && (
        <Lightbox
          mainSrc={data.recapPost.images[mediaData.photoIndex].image.localFile.publicURL}
          nextSrc={data.recapPost.images[(mediaData.photoIndex + 1) % size(data.recapPost.images)].image.localFile.publicURL}
          prevSrc={
            data.recapPost.images[(mediaData.photoIndex + size(data.recapPost.images) - 1) % size(data.recapPost.images)]
          }
          onCloseRequest={() =>
            setMediaData({ ...mediaData, ...{ isVisible: false } })}
          onMovePrevRequest={() =>
            setMediaData({
              ...mediaData,
              ...{
                photoIndex:
                  (mediaData.photoIndex + size(data.recapPost.images) - 1) % size(data.recapPost.images),
              },
            })}
          onMoveNextRequest={() =>
            setMediaData({
              ...mediaData,
              ...{ photoIndex: (mediaData.photoIndex + 1) % size(data.recapPost.images) },
            })}
        />
      )}
    </ImageSliderWrapper>
  )
}

export default ImageSlider