/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react'

// Images
import redChevronRight from 'img/snel_red_chevron_right.svg'

// Components
import { Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledCustomLink = styled(CustomLink)`
  ${Paragraph} {
    transition: 0.25s;
    width: 264px;
    height: 50px;

    :hover {
      transform: scale(1.05);
    }
  }
`

const LinkCustomLink = styled(CustomLink)`
  &[aria-current] {
    ${Paragraph} {
      color: ${(props) => props.theme.color.text.striking};
    }
  }
`

const OtherArticlesWrapper = styled.div``

const ArticlesContainer = styled.div`
  width: 264px;
`

const Links = styled.div``

const OtherArticles = ({ data, className }) => {
  return (
    <OtherArticlesWrapper className={`${className ? `${className}` : ``}`}>
      <ArticlesContainer className="d-flex justify-content-end flex-wrap">
        <StyledCustomLink to="/nieuws">
          <Paragraph className="float-left py-2 px-4 d-flex justify-content-center align-items-center color-background-main color-text-light text-uppercase font-weight-xl font-size-m">Meer nieuws</Paragraph>
        </StyledCustomLink>
        <Links className="d-flex justify-content-end flex-wrap pt-3">
          {data.edges.map((article, index) => (
            <LinkCustomLink to={`/nieuws/${article.node.slug}`} className="d-flex pl-4 flex-wrap justify-content-center align-items-center w-100 px-2 py-2" key={index}>
              <div className="d-flex w-100 justify-content-start">
                <img className="pr-2" src={redChevronRight} alt="" />
                <Paragraph className="text-uppercase font-weight-xl">{article.node.title}</Paragraph>
              </div>
            </LinkCustomLink>
          ))}
        </Links>
      </ArticlesContainer>
    </OtherArticlesWrapper>
  )
}

export default OtherArticles