/* eslint-disable arrow-body-style */
import React from 'react'


// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'


// Components
import ButtonAlt from '../Elements/ButtonAlt'


const BannerWrapper = styled.div``

const ContentContainer = styled.div`
  h2 {
    color: ${(props) => props.theme.color.text.light};
    font-size: ${(props) => props.theme.font.size.xxl};
    line-height: 1.22;
    text-transform: uppercase;
    font-weight: ${(props) => props.theme.font.weight.xl};
    margin: 0;
  }

  p {
    color: ${(props) => props.theme.color.text.light};
  }

  @media (max-width: 991px) {
    h2, p {
      text-align: center;
    }
  }

  @media (max-width: 575px) {
    h2 {
      font-size: ${(props) => props.theme.font.size.l};
    }
  }
`

const StyledImg = styled.img`
  height: 227px;
  bottom: -100px;
  left: 50px;

  @media (max-width: 991px) {
    left: 0;
  }
`

const DesktopImgContainer = styled.div``

const MobileImgContainer = styled.div`
  ${StyledImg} {
    left: -50px;
    bottom: -45px;
  }

  @media (max-width: 575px) {
    max-height: 200px;
    ${StyledImg} {
      bottom: -15px;
      left: 0;
    }
  }

  @media (max-width: 400px) {
    ${StyledImg} {
      left: 20px;
    }
  }
`

const CTABanner = ({ data, className }) => {
  return (
    <BannerWrapper className={`color-background-main ${className ? `${className}` : ``}`}>
      <div className="container pt-3 py-lg-5">
        <div className="row py-3">
          <DesktopImgContainer className="col-lg-3 d-lg-block d-none position-relative">
            <StyledImg className="position-absolute" src={data.recapPost.banner.image.localFile.publicURL} alt="" />
          </DesktopImgContainer>
          <div className="col-12 col-lg-5 d-flex justify-content-lg-start justify-content-center align-items-center">
            <ContentContainer>
              {parse(data.recapPost.banner.content)}
            </ContentContainer>
          </div>
          <MobileImgContainer className="col-5 col-sm-6 d-lg-none d-flex justify-content-end position-relative">
            <StyledImg className="position-relative" src={data.recapPost.banner.image} alt="" />
          </MobileImgContainer>
          <div className="col-7 col-sm-6 col-lg-4 d-flex justify-content-lg-end justify-content-start align-items-center">
            {data.recapPost.banner.link && data.recapPost.banner.link.url && (
              <ButtonAlt to={data.recapPost.banner.link.url}>
                {data.recapPost.banner.link.title}
              </ButtonAlt>
            )}
            {data.recapPost.banner.button_banner && data.recapPost.banner.button_banner.url && (
              <ButtonAlt to={data.recapPost.banner.button_banner.url}>
                {data.recapPost.banner.button_banner.title}
              </ButtonAlt>
            )}
          </div>
        </div>
      </div>
    </BannerWrapper>
  )
}

export default CTABanner