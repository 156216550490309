import React from 'react'

// Images
import makelaar from 'img/snel_makelaar_1.svg'

// Components
import { SecondHeader, Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledCustomLink = styled(CustomLink)`
  ${Paragraph} {
    z-index: 9;
    position: relative;
    transition: 0.25s;
    border-radius: 59px;
    text-transform: initial !important;
  }
  
`

const HuisOpgevenWrapper = styled.div`

  ${SecondHeader} {
    float: left;
    line-height: 1.41;
    letter-spacing: -0.29px;
  }
`

const HuisOpgevenContainer = styled.div`
  width: 348px;
  height: 184px;
  
  ::before {
    content: "";
    position: absolute;
    display: block;
    background-image: url(${makelaar});
    background-repeat: no-repeat;
    background-size: contain;
    height: 261px;
    width: 230px;
    left: -70px;
    bottom: -60px;
  }
`

const CloudWrapper = styled.div`
  position: relative;
  min-width: 200px;

  left: 25px;
  top: -45px;
`

const Cloud = styled.div`
  border: 1px solid #242944;
  border-radius: 5px;
  z-index: 2;

  height: 108px;
  width: 197px;

  ${Paragraph} {
    line-height: 1.29;
  }

  ${SecondHeader} {
    max-height: 32px;
  }

  ::after {
    top: 45%;
    right: calc(100% - 15px);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #fff;
    border-width: 15px;
    margin-left: -15px;
    transform: rotate(225deg);
    z-index: 3;
  }

  ::before {
    top: 45%;
    right: calc(100% - 16.5px);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-width: 1px;
    border-top-color: #242944;
    border-width: 16.5px;
    margin-left: -15.5px;
    transform: rotate(225deg);
    z-index: 1;
  }
`

const HuisOpgeven = ({ route, className }) => (
  <HuisOpgevenWrapper className={`d-flex ${className ? `${className}` : ``}`}>
    {route ? (
      <HuisOpgevenContainer className="position-relative color-background-main">
        <div className="d-flex justify-content-center pt-4">
          <SecondHeader className="color-text-light font-weight-xl font-size-22">
            Jouw woning ook in de
            <br />
            Open Huizen Route?
          </SecondHeader>
        </div>
        <div className="d-flex justify-content-end pr-4 pt-4">
          <StyledCustomLink to="/contact">
            <Paragraph className="text-uppercase py-2 px-4 float-left color-background-striking color-text-light font-weight-xl font-size-m">Neem contact op</Paragraph>
          </StyledCustomLink>
        </div>
      </HuisOpgevenContainer>
    ) : (
      <HuisOpgevenContainer className="position-relative color-background-main">
        <div className="d-flex justify-content-center pt-4">
          <CloudWrapper>
            <Cloud theme={{ type: 'small' }} className="d-flex position-relative color-background-light px-3 py-2">
              <div>
                <Paragraph className="font-size-sm pb-2">
                  Klanten beoordelen
                  <br />
                  ons gemiddeld:
                </Paragraph>
                <CustomLink external to="https://www.funda.nl/makelaars/leusden/80-snel-era-makelaars/beoordelingen/verkoop/" className="font-size-sm">
                  <i>Lees reviews</i>
                </CustomLink>
              </div>
              <div className="d-flex justify-content-end align-items-start pt-1 pl-4">
                <SecondHeader className="color-text-striking font-size-xxl font-weight-xl d-flex justify-content-end align-items-center">9</SecondHeader>
              </div>
            </Cloud>
          </CloudWrapper>
        </div>
        <div className="d-flex justify-content-end pr-4 pb-4">
          <StyledCustomLink to="/contact">
            <Paragraph className="text-uppercase py-2 px-4 float-left color-background-striking color-text-light font-weight-xl font-size-m">Neem contact op</Paragraph>
          </StyledCustomLink>
        </div>
      </HuisOpgevenContainer>
    )}

  </HuisOpgevenWrapper>
)

export default HuisOpgeven